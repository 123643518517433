<template>
  <div class="learnRecordList">
    <div class="learnTab">
      <p
        class="tabItem"
        :class="{ tabActive: learnTabIndex === item.key }"
        @click="handleTab(item)"
        v-for="item in learnTab"
        :key="item.key"
      >
        {{ item.value }} ( {{ item.num }} )
      </p>
    </div>
    <div class="list">
      <div class="item" v-for="item in learnList" :key="item.id">
        <template v-if="item.type === 3">
          <img src="@/assets/images/taskbg.png" class="cover" alt="" />
        </template>
        <template v-else>
          <img :src="item.coverImage" class="cover" alt="" />
        </template>
        <div class="course-source">
          <img :src="ConstData.courseSource[item.type || 1]?.picUrl">
        </div>
        <div class="info">
          <p class="name" :title="item.name">{{ item.name }}</p>
          <p class="btn" @click="goDetail(item)">
            <span>开始学习</span>
          </p>
        </div>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>暂无数据</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      learnList: [],
      learnList2: [],
      learnTabIndex: 1,
      paging: {
        params: {
          pageNum: 1,
          pageSize: 9,
        },
        total: 0,
      },
      learnTab: [
        { key: 1, value: "学习中", num: 0 },
        { key: 2, value: "已完成", num: 0 },
      ],
      loading: false,
      noData: false,
    };
  },
  computed: {
    noMore() {
      return (
        this.learnList.length === this.paging.total && this.paging.total !== 0
      );
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {},
  async mounted() {
    await this.getMyTaskList(1, "created");
    // await this.getMyTaskList(2, "created");
    $(window).scroll(() => {
      //判断是否滑动到页面底部
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
        // console.log('页面底部')

        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    goDetail(item) {
      if (item.type === 3) {
        this.$router.push({
          path: "/learn/task/detail",
          query: {
            id: item.id,
          },
        });
      } else {
        if (item.displayStyle === 1) {
          this.$router.push({
            path: "/learn/course/detail",
            query: {
              id: item.id,
            },
          });
        } else {
          this.$router.push({
            path: "/learn/StageMap",
            query: {
              id: item.id,
            },
          });
        }
      }
    },
    reachBottom() {
      if (
        this.paging.total >
        this.paging.params.pageNum * this.paging.params.pageSize
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.getMyTaskList(this.learnTabIndex);
      } else {
        this.loading = false;
      }
    },
    getMyTaskList(status, type) {
      const params = {
        ...this.paging.params,
        status,
      };
      console.log(params, "========");
      return this.$api.learn.getMyTaskList({ params }).then((res) => {
        if (res.data) {
          if ((type && status === 1) || !type) {
            if (res.data.list && res.data.list.length) {
              this.noData = false;
            } else {
              this.noData = true;
            }
          }
          if (this.paging.params.pageNum > 1) {
            this.learnList = this.learnList.concat(res.data.list);
          } else {
            if (type && status === 1) {
              this.learnList = res.data.list || [];
              this.paging.total = res.data.total;
            } else if (!type) {
              this.learnList = res.data.list || [];
              this.paging.total = res.data.total;
            }
          }
          if (type) {
            if (status === 1) {
              this.learnTab[0].num = res.data.total;
            } else {
              this.learnTab[1].num = res.data.total;
            }
          }
        }
      });
    },
    async handleTab(item) {
      this.paging.params.pageNum = 1;
      this.learnTabIndex = item.key;
      await this.getMyTaskList(this.learnTabIndex);
    },
  },
};
</script>

<style lang="scss" src="../asset/css/list.scss" scoped></style>
<style lang="scss" scoped>
.item{
  position: relative;
  .course-source {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    img{
      height: 24px;
    }
  }
}
</style>

